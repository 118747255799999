import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import banner1 from "../../../assets/img/banner/banner1.jpg";
import banner2 from "../../../assets/img/banner/banner2.jpg";
import banner3 from "../../../assets/img/banner/banner3.jpg";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
const bannerData = bannerDB;
function Banner() {
  const [data, setData] = useState();
  const baseUrl = base_url();

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      alert("Server Error BannerList");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="bannerSection d-none">
        <Slider {...settings}>
          {data &&
            data.slice(0, 2).map((item) => {
              return (
                item?.image?.url && (
                  <Link to={item?.url || ""} key={item._id}>
                    <div className="bannerItem">
                      <img src={item?.image.url} className="img-fluid" />
                    </div>
                  </Link>
                )
              );
            })}
        </Slider>
      </section>

      <section className="bannerSection">
        <Slider {...settings}>
          <Link to={"/"}>
            <div className="bannerItem">
              <img src={banner1} className="img-fluid" />
            </div>
          </Link>
          <Link to={"/"}>
            <div className="bannerItem">
              <img src={banner2} className="img-fluid" />
            </div>
          </Link>
          <Link to={"/"}>
            <div className="bannerItem">
              <img src={banner3} className="img-fluid" />
            </div>
          </Link>
        </Slider>
      </section>
    </>
  );
}

export default Banner;
