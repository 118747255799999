import React from "react";
import award1 from "../../../assets/img/videoplayback1.mp4";
import award2 from "../../../assets/img/videoplayback2.mp4";
import award3 from "../../../assets/img/sergery.png";

const data = [
  { id: "1", url: award1, title: "Corporate Video", category: "video" },
  { id: "2", url: award2, title: "Product Video", category: "video" },
  { id: "3", url: award3, title: "Surgery", category: "photo" },
];

const CorporateVideo = () => {
  return (
    <>
      <section className="awardSec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3>Surgery Videos</h3>
              </div>
            </div>
            {data.map((item) => {
              return (
                <div className="col-md-4" key={item.id}>
                  {item.category === "video" ? (
                    <div className="videoCard">
                      <video
                        muted
                        autoPlay="true"
                        loop
                        width={"100%"}
                        src={item.url}
                        alt="Awards"
                      />
                    </div>
                  ) : (
                    <div className="awardCard1">
                      <img
                        src={item.url}
                        alt={item.title}
                        className="img-fluid"
                      />
                    </div>
                  )}
                  <div className="videoText">
                    <h5>{item.title}</h5>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default CorporateVideo;
