import React from "react";

import a1 from "../../../assets/img/cnc-MACHINE.jpg";
function AboutStory({ data, t }) {
  return (
    <>
      <div className="aboutSection p-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="image">
                <img src={a1} alt="" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="container">
                <h3 className="subtitle">{t("About Us")}</h3>
                {/* {data?.about_company} */}
                <p>
                  Shagun Cares manufactures complete range of Orthopedic
                  Implants and Oncology Implants. We are an ISO 9001-2015
                  Certified Company with ISO:13485: 2016 , G.M.P AND CE , F.D.A
                  (India) Certified Products
                </p>
                {/* <p>
                  {t('about-1')}
                </p> */}
                <br />
                <h4>
                  We Employ Latest Technology and Research at our
                  State-of-the-Art Manufacturing Facility
                </h4>
                <p>
                  At our very own manufacturing facility at Ghaziabad U.P our
                  team is continually innovating and designing new advances in
                  systems for Orthopedic Implants and Oncology Implants. All our
                  implants and instruments are rigorously tested and
                  ultrasonically cleaned before shipping to ensure best quality
                  and safety
                </p>
              </div>
              <div className="d-flex mt-5">
                <div className="countItem me-5">
                  <div className="count-wrapper line-height-1">
                    <h2>700+</h2>
                  </div>
                  {/* End .count-wrapper */}
                  <p className="count-title">{t("Range")}</p>
                </div>

                <div className="countItem">
                  <div className="count-wrapper line-height-1">
                    <h2>50+</h2>
                  </div>
                  {/* End .count-wrapper */}
                  <p className="count-title">{t("Expert Staff")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
