import React from "react";

function AboutChoose({ t }) {
  return (
    <>
      <section className="featuresSection p-30">
        <div className="container">
          <h3 className="subtitle mb-3 text-capitalize">
            {t("why choose us")}
          </h3>
          <p>We offer  the biggest range of Orthopedic Implants, Oncology Implants and Arthroscopy products in India </p>

          
          <div className="row">
            <div className="col-lg-3">
              <div className="feature-box bg-white">
                <i className="icon-shipped" />
                <div className="feature-box-content1">
                  <h5>{t("Customization")}</h5>
                  {/* <p>
                    {t('inabout')}
                  </p> */}
                  <p>
                    We are the first company in India to offer customization of
                    Orthopedic Implants and Oncology Implants according to
                    patient requirements
                  </p>
                </div>
                {/* End .feature-box-content1 */}
              </div>
              {/* End .feature-box */}
            </div>
            {/* End .col-lg-4 */}
            <div className="col-lg-3">
              <div className="feature-box bg-white">
                <i className="icon-us-dollar" />
                <div className="feature-box-content1">
                  <h5>{t("High Quality")}</h5>
                  <p>
                    {/* {t('about-2')} */}
                    We use latest technology, skilled workmanship and
                    world-class materials to ensure the highest quality products
                    in orthopedic implants
                  </p>
                </div>
                {/* End .feature-box-content1 */}
              </div>
              {/* End .feature-box */}
            </div>
            {/* End .col-lg-4 */}
            <div className="col-lg-3">
              <div className="feature-box bg-white">
                <i className="icon-online-support" />
                <div className="feature-box-content1">
                  <h5> {t("Unmatched Range")}</h5>
                  {/* <p>{t("cont")}</p> */}
                  <p>
                    We have the biggest range of Orthopaedic Implants and
                    Oncology Implants in India
                  </p>
                </div>
                {/* End .feature-box-content1 */}
              </div>
              {/* End .feature-box */}
            </div>
            {/* End .col-lg-4 */}

            {/* End .col-lg-4 */}
            <div className="col-lg-3">
              <div className="feature-box bg-white">
                <i className="icon-online-support" />
                <div className="feature-box-content1">
                  <h5> {t("Global Reach")}</h5>
                  {/* <p>{t("cont")}</p> */}
                  <p>
                    We have export channels in 28 countries across the world
                  </p>
                </div>
                {/* End .feature-box-content1 */}
              </div>
              {/* End .feature-box */}
            </div>
            {/* End .col-lg-4 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </section>
    </>
  );
}

export default AboutChoose;
