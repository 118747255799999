import React from "react";
import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";

import "./ServiceList.css";

import product1 from "../../../assets/img/serviceList/1.jpg";
import product2 from "../../../assets/img/serviceList/2.jpg";
import product3 from "../../../assets/img/serviceList/3.jpg";
import product4 from "../../../assets/img/serviceList/4.jpg";
import product5 from "../../../assets/img/serviceList/5.jpg";
import product6 from "../../../assets/img/serviceList/6.jpg";
import product7 from "../../../assets/img/serviceList/7.jpg";
import product8 from "../../../assets/img/serviceList/8.jpg";
import product9 from "../../../assets/img/serviceList/9.jpg";
import product10 from "../../../assets/img/serviceList/10.jpg";
import product11 from "../../../assets/img/serviceList/11.jpg";
import product12 from "../../../assets/img/serviceList/12.jpg";

const data = [
  { id: "1", title: "Spine", url: product1 },
  { id: "2", title: "Pre Loaded Anchor", url: product2 },
  { id: "3", title: "Anchor Knot Less", url: product3 },
  { id: "4", title: "Endobutton", url: product4 },
  { id: "5", title: "Interference Square (Peek)", url: product5 },
  { id: "6", title: "Patella Plate", url: product6 },
  { id: "7", title: "Peek External fixators", url: product7 },

  { id: "8", title: "Variable Angle Distal Radius Plate", url: product8 },
  { id: "9", title: "Hoffman II", url: product9 },
  { id: "10", title: "Diaphyseal Spacer Implant", url: product10 },
  { id: "11", title: "Radial Head Prosthesis System", url: product11 },
  { id: "12", title: "Peek Cages for Spine", url: product12 },
];
 
function ServiceList() {
  return (
    <>
      <section className="serviceList sectionPD">
        <div className="container">
          <div className="aboutWeb">
            <h5>Shagun Cares Inc.</h5>
            <h3>
              Manufacturing biggest range of Orthopedic and Oncology  Implants in <br />
              India under one roof!!!
            </h3>
            <p>
              Shagun Cares Inc. is an ISO 9001-2015 Certified Company with
              ISO:13485 2016 , G.M.P, CE, F.D.A (India) Certified products. We
              export and have distribution channel for our products in 28
              different countries and have successfully supplied our clients
              regularly from past 15 Years.
            </p>
          </div>
          
          {/* <div className="serviceItemInfo">
            <div className="row">
              <div className="col-md-12">
                <div className="fisherman-content">
                  <h3>Latest Additions</h3>
                </div>
              </div>
              {data.map((item) => {
                return (
                  <div className="col-md-3" key={item.id}>
                    <div className="serviceItem">
                      <div className="serviceItemImg">
                        <img
                          src={item.url}
                          className="img-fluid"
                          alt={item.title}
                          title={item.title}
                        />
                      </div>
                      <div className="serviceItemText">
                        <h5>
                          <Link to="/products">
                            {item.title}{" "}
                            <i className="ri-arrow-right-up-fill"></i>
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default ServiceList;
