import React from "react";
import award1 from "../../../assets/img/01.jpg";
import award2 from "../../../assets/img/02.jpg";
import award3 from "../../../assets/img/03.jpg";
import award4 from "../../../assets/img/04.jpg";
import award5 from "../../../assets/img/05.jpg";
import award6 from "../../../assets/img/06.jpg";
import award7 from "../../../assets/img/07.jpg";

const data = [
  { id: "1", url: award1 },
  { id: "2", url: award2 },
  { id: "3", url: award3 },
  { id: "4", url: award4 },
  { id: "5", url: award5 },
  { id: "6", url: award6 },
  { id: "7", url: award7 },
];

const Awards = () => {
  return (
    <>
      <section className="awardSec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            <div className="fisherman-content">
              <h3>Awards</h3>
            </div>
            </div>
            {data.map((item) => {
              return (
                <div className="col-md-3" key={item.id}>
                  <div className="awardCard">
                    <img src={item.url} alt="Awards" className="img-fluid" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Awards;
